import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import axios from "axios";
import TextSkeleton from "../components/TextSkeleton";

const Home = () => {

  const [ suites, setSuites ] = useState([]);
  const { orgId } = useParams();

  const getSuiteRow = (suite) =>{
    return (
      <Link className="hover:text-white rounded-lg hover:bg-white/10 py-1 px-2" to={`${suite.id}/${suite.name}`}>
        { suite.name }
      </Link>
    )
  }

  useEffect(()=>{

    const func = async () =>{
      const suites = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/suites`);
      
      setSuites(suites?.data);
    }

    func();

  },[])
  
  return (
    <div className="text-primary-font-color h-full py-6 px-8 md:px-12 md:py-8 3xl:px-24 3xl:py-20 flex flex-col">
      <h1 className="text-primary-font-color text-4xl 3xl:text-6xl font-medium h-fit">
        Suites
      </h1>
      <div className="border-t border-white/10 w-full h-[1px] my-3 3xl:my-4">
      </div>
      <div className="flex flex-col gap-3 3xl:text-xl grow overflow-y-auto">
        {
          suites?.length > 0 ? suites?.map((suite)=>(
            getSuiteRow(suite)
          ))
          :
          <div className="flex flex-col gap-2">
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
          </div>
        }
      </div>
    </div>
  )
}

export default Home