import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import ModuleCard from "./ModuleCard";
import TextSkeleton from "./TextSkeleton";
import ModuleCardSkeleton from "./ModuleCardSkeleton";

const Suite = (props) => {

    const navigate = useNavigate();
    const { suiteName } = useParams();
    const [ status, setStatus ] = useState(200);
    

    // useEffect(()=>{
    //     // make the req to get modules
        
    //     if( status == 200 ){
            
    //     }
    //     if status id 403
    //     else
    //         navigate(`/error/${status}`, { replace: true });
    // }, [])

  return (
    <div className="px-8 py-6 md:px-12 md:py-8 rounded-lg h-full flex flex-col">
        <h1 className="text-primary-font-color text-2xl md:text-4xl 3xl:text-6xl font-medium h-fit">
            { suiteName } Suite
        </h1>
        <div className="border-t border-white/10 w-full h-[1px] my-4 3xl:my-6">
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 lg:grow overflow-y-auto p-1">
            {
                props.modules?.length > 0 ? props.modules.map((module)=><ModuleCard key={ module.id } data={module} />)
                :
                (
                    [1,2,3,4,5].map((item, index)=><ModuleCardSkeleton key={index} />)
                )
            }
        </div>
    </div>
  )
}

export default Suite