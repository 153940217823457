import React from 'react'
import { Link } from "react-router-dom"
import moment from "moment"

const ModuleCard = (props) => {
  return (
    <div className="lg:relative max-h-60 3xl:max-h-64 overflow-visible md:w-full">
        <Link to={`modules/${props.data.id}`} className="lg:absolute w-full md:h-full border border-primary-font-color rounded-lg p-2 3xl:p-4 flex flex-col gap-4 justify-between hover:scale-[1.01] transition-all duration-300 ease-out">
            <div className="text-primary-font-color px-2 3xl:px-3 flex flex-col gap-2 3xl:gap-4 grow">
                <div className="flex justify-between items-start h-1/2 gap-2">
                    <p title={props.data.name} className="text-lg md:text-xl lg:text-2xl 3xl:text-3xl font-medium w-full line-clamp-2 h-fit">
                        {props.data.name}
                    </p>
                    {
                        props.data?.recordingDevice === "Desktop" ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="stroke-autoflow-yellow w-5 3xl:w-7 mt-1 3xl:mt-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                            </svg>
                        : props.data?.recordingDevice === "Tab" ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="stroke-autoflow-yellow w-5 3xl:w-7 mt-1 3xl:mt-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-15a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 4.5v15a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>                  
                        : props.data?.recordingDevice === "Mobile" ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="stroke-autoflow-yellow w-5 3xl:w-7 mt-1 3xl:mt-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                            </svg>                  
                        : null
                    }

                </div>
                <div className="h-1/2">
                    <p className="text-xs font-medium lg:text-sm bg-autoflow-yellow rounded-full px-2 w-fit text-primary-color-dark">
                        <span className="">
                            {props.data.numberOfFlows}
                        </span>
                        { props.data.numberOfFlows === 1 ? " Flow" : " Flows"}
                    </p>
                    <p className="text-xs md:text-sm lg:text-base 3xl:text-lg mt-1 3xl:mt-2">
                        {"Last updated "}
                        <span className="font-medium">
                            { moment(props.data.lastUpdatedAt, 'YYYYMMDD').fromNow() }
                        </span>
                    </p>
                </div>
            </div>
            <Link to={`modules/${props.data.id}`} className="flex justify-center items-center gap-2 text-xs md:text-sm lg:text-base 3xl:text-lg text-primary-font-color hover:text-autoflow-yellow hover:font-medium border border-autoflow-yellow transition-colors duration-300 ease-out text-center py-2 md:py-3 lg:py-4 w-full rounded-lg group">
                <span>
                    Explore
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-0 group-hover:w-4 transition-all duration-300 ease-out stroke-autoflow-yellow stroke-[1.5] group-hover:stroke-[2]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
            </Link>
        </Link>
    </div>
  )
}

export default ModuleCard